import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import {
  Pagination,
  PaginationType,
  Breadcrumb,
  QaCard,
  Wrapper,
  Flex,
  SEO,
  QaInstantSearchResult,
  SearchBox,
  WordCloud,
  Layout,
} from '@renderbus/common/components'
import { spacing, Media } from '@renderbus/common/theme'
import { sortByTime } from '@renderbus/common/utils'

import AdBanner from '../components/ad-banner'
import {
  SectionContainer,
  SectionWrapper,
  SearchWrapper as SearchWrapperContainer,
  SearchResultList,
} from '../style/common'
import SearchEngine from '../components/search/search-engine'
const PaginationWrapper = styled(Flex)`
  margin: ${spacing.base} 0;
  padding: 0 20px;
  justify-content: flex-start;
`
const QaWrapper = styled(Wrapper)`
  max-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgb(240, 240, 240);
  ${Media.lessThan(Media.small)} {
    padding: 0px;
    background-color: white;
  }
`
const SearchWrapper = styled(Wrapper)`
  max-width: 100%;
  padding-top: 30px;
  background-color: rgb(240, 240, 240);
  ${Media.lessThan(Media.small)} {
    padding: 20px 0px;
    background-color: white;
    max-width: 80%;
  }
`
const BreadcrumbWrapper = styled(Wrapper)`
  max-width: 1000px;
  margin: 0px auto;
  margin-top: 20px;
  align-items: flex-start;
  justify-content: flex-start;
`
class Index extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { cardList: [] }
    this.cardList = {
      post: this.props.data.post.nodes,
      term: this.formatCardList(this.props.data.term.nodes),
    }[this.props.pageContext.type]
  }

  formatCardList(data) {
    if (data && data[0]) {
      let { qa } = data[0]
      if (qa) {
        qa = sortByTime(qa)
        const skip = this.props.pageContext.skip
        const limit = this.props.pageContext.limit
        if (qa.length > limit) {
          qa = qa.slice(skip, skip + limit)
        }
        return qa.map(item => {
          return {
            answer: item.answer,
            tinyMceContent: item.tinyMceContent,
            question: item.question,
            tags: item.tags,
            createdAt: item.createdAt,
            slug: item.slug,
          }
        })
      }
    }
    return []
  }

  render() {
    const { location, pageContext, data } = this.props
    const { type, title, keywords, description, topThreeShare } = pageContext
    const seoTitle = {
      term: title,
      post: '渲染问答:MAX、Maya、C4D、Blender等渲染,云渲染常见问题大全',
    }[type]
    const seoKeywords = {
      term: keywords,
      post: '渲染常见问题大全',
    }[type]
    const seoDescription = {
      term: description || '',
      post:
        '渲染常见问题大全,MAX,Maya,C4D,Blender,SketchUp等渲染,云渲染问题原因及解决方案,渲染一张图多久,多少钱,为什么会有噪点,为什么全黑,贴图丢失,如何免费云渲染等',
    }[type]
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO title={seoTitle} keywords={seoKeywords} description={seoDescription} />
        <WordCloud wordCloudArray={data.cloudTerm.nodes} />
        <AdBanner />
        <SearchWrapper>
          <SearchEngine
            type='qa'
            render={({ type, query, result, handleChange, handleSearch }) => (
              <SearchWrapperContainer style={{ maxWidth: '1000px', margin: '0 auto' }}>
                <div>
                  <SearchBox
                    type={type}
                    searchContent={query}
                    onSearch={handleSearch}
                    onChange={handleChange}
                  />
                </div>
                <SearchResultList isShow={query}>
                  <QaInstantSearchResult result={result} searchContent={query} />
                </SearchResultList>
              </SearchWrapperContainer>
            )}
          />
        </SearchWrapper>
        <QaWrapper>
          <BreadcrumbWrapper>
            {type === 'term' && (
              <Breadcrumb separator='>'>
                <Breadcrumb.Item href='/'>渲染常见问题大全</Breadcrumb.Item>
                {data.term.nodes.length > 0 ? (
                  <Breadcrumb.Item href={`/${data.term.nodes[0].slug}/`}>
                    {data.term.nodes[0].name}
                  </Breadcrumb.Item>
                ) : null}
              </Breadcrumb>
            )}
          </BreadcrumbWrapper>
          <SectionWrapper>
            <SectionContainer>
              {this.cardList.map((node, index) => {
                return (
                  <QaCard
                    key={index}
                    question={node.question}
                    tags={node.tags}
                    answer={node.answer ? node.answer.answer : ''}
                    tinyMceContent={node.tinyMceContent ? node.tinyMceContent.tinyMceContent : ''}
                    slug={node.slug}
                    date={node.createdAt}
                  />
                )
              })}
              <PaginationWrapper>
                <Pagination
                  type={PaginationType.Static}
                  currentPage={pageContext.currentPage}
                  total={pageContext.numPages}
                />
              </PaginationWrapper>
            </SectionContainer>
          </SectionWrapper>
        </QaWrapper>
      </Layout>
    )
  }
}

export default Index

export const IndexQuery = graphql`
  query($skip: Int!, $limit: Int!, $slug: String!) {
    post: allContentfulQa(sort: { fields: createdAt, order: DESC }, limit: $limit, skip: $skip) {
      nodes {
        id
        question
        answer {
          answer
        }
        tinyMceContent {
          tinyMceContent
        }
        tags {
          name
          slug
        }
        slug
        createdAt(formatString: "YYYY-MM-DD")
      }
      totalCount
    }
    term: allContentfulQaTerm(filter: { slug: { eq: $slug } }) {
      nodes {
        name
        slug
        qa {
          question
          answer {
            answer
          }
          tinyMceContent {
            tinyMceContent
          }
          tags {
            name
            slug
          }
          slug
          createdAt(formatString: "YYYY-MM-DD kk:mm:ss")
        }
      }
    }
    cloudTerm: allContentfulQaTerm {
      nodes {
        name
        slug
      }
    }
  }
`
